<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <validation-observer v-slot="{ handleSubmit }" ref="VForm">
            <b-form @submit.prevent="handleSubmit(submitLegal)">
              <b-card no-body>
                <template #header>
                  <h5 class="card-title">{{row.asc_name}}</h5>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col md=6>
                      <b-form-group label="Content (ID)" label-for="desc_id">
                        <CKEditor id="desc_id" :value.sync="row.asc_content_id" />
                        <VValidate name="Content (ID)" v-model="row.asc_content_id" :rules="mrValidation.asc_content_id" />
                      </b-form-group>
                    </b-col>
                    <b-col md=6>
                      <b-form-group label="Content (EN)" label-for="desc_en">
                        <CKEditor id="desc_en" :value.sync="row.asc_content_en" />
                        <VValidate name="Content (EN)" v-model="row.asc_content_en" :rules="mrValidation.asc_content_en" />
                      </b-form-group>
                    </b-col>
                    <b-col md=6>
                      <b-form-group label="Content (JP)" label-for="desc_en">
                        <CKEditor id="desc_en" :value.sync="row.asc_content_jp" />
                        <VValidate name="Content (JP)" v-model="row.asc_content_jp" :rules="mrValidation.asc_content_jp" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-card-footer>
                  <div class="text-right">
                    <b-button variant="primary" class="btn-rounded" type="submit">Save Changes</b-button>
                  </div>
                </b-card-footer>
              </b-card>
            </b-form>
          </validation-observer>
        </b-tab>

        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import CKEditor from '@/components/CKEditor'
import SEOSection from '@/components/SEOSection'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    CKEditor,
    SEOSection
  },
  data() {
    return {
      mrValidation: {},
      itemsToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ],
      seoPageSetting: {},
      seoValidation: {},
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    submitLegal(){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'update'
        }, this.row),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true
            this.apiGet()
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VForm'
      )
    }
  },
  watch: {
    $route() {
      this.apiGet()
    },
        // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>